<div>
  <h1 mat-dialog-title>{{gameData?.title}}</h1>
  <button [matTooltip]="gamePages.length > 4 ? 'Trop de lignes' : ''"
          [disabled]="gamePages.length > 4" mat-raised-button color="primary" (click)="createGamePage()">Créer
  </button>

  <mat-table *ngIf="gamePages && gamePages.length>0" [dataSource]="gamePages">

    <!-- Titre Column -->
    <ng-container matColumnDef="title">
      <mat-header-cell *matHeaderCellDef> Titre</mat-header-cell>
      <mat-cell *matCellDef="let gamePage"> {{gamePage.title}} </mat-cell>
    </ng-container>

    <!-- État Column -->
    <ng-container matColumnDef="state">
      <mat-header-cell *matHeaderCellDef> État</mat-header-cell>
      <mat-cell *matCellDef="let gamePage"> {{gamePage.state}} </mat-cell>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef> Actions</mat-header-cell>
      <mat-cell *matCellDef="let gamePage">
        <button mat-button (click)="editGamePage(gamePage)">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-button (click)="deleteGamePage(gamePage)">
          <mat-icon>delete</mat-icon>
        </button>
        <button [disabled]="gamePages.length > 4" mat-button (click)="duplicateGamePage(gamePage)">
          <mat-icon [matTooltip]="gamePages.length > 4 ? 'Trop de lignes' : ''">content_copy</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="['title', 'state', 'actions']"></mat-header-row>
    <mat-row *matRowDef="let row; columns: ['title', 'state', 'actions'];"></mat-row>

  </mat-table>
</div>
