import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {GamePageService} from "../../../swagger/api/gamePage.service";
import {Game} from "../../../swagger/model/game";
import {GamePage} from "../../../swagger/model/gamePage";
import {CreerGamepageComponent} from "./creer-gamepage/creer-gamepage.component";
import {SnackBarService} from "../../../services/snack-bar.service";
import {ActionsEnum} from "../../../snack-bar/enum-actions";
import {PannelsClassEnum} from "../../../snack-bar/pannels-class-enum";
import {IndiepassCodesEnum} from "../../../../model/enums/indiepass-codes-enum";
import {Router} from "@angular/router";

@Component({
  selector: 'app-choix-page-popup',
  templateUrl: './choix-page-popup.component.html',
  styleUrls: ['./choix-page-popup.component.scss']
})
export class ChoixPagePopupComponent {
  public accepte: boolean = false;
  public gamePages: GamePage[] = [];
  private game: Game = {};

  constructor(public dialog: MatDialog,
              private router: Router,
              public snackBarService: SnackBarService,
              public gamePageService: GamePageService,
              public dialogRef: MatDialogRef<ChoixPagePopupComponent>,
              @Inject(MAT_DIALOG_DATA) public gameData: Game,
  ) {
    this.game = gameData;
    if (gameData.id !== undefined) {
      this.refreshGamePageList(gameData.id);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  createGamePage(): void {
    const dialogRef = this.dialog.open(CreerGamepageComponent, {
      width: '550px',
      data: this.game,
    });
    dialogRef.afterClosed().subscribe(gamePageCreated => {
      if (gamePageCreated) {
        if (this.game.id !== undefined) {
          this.refreshGamePageList(this.game.id);
          this.dialogRef.close();
        }
      }
    })
  }

  editGamePage(gamePage: GamePage): void {
    if (gamePage.id !== undefined) {
      this.gamePageService.getGamePageById(gamePage.id).subscribe(response => {
        this.router.navigate(["/create/"+gamePage.id])
        this.dialogRef.close();
      })
    }
  }

  deleteGamePage(gamePage: GamePage): void {
    if (gamePage.id !== undefined) {
      this.gamePageService.deleteGamePageById(gamePage.id).subscribe(response => {
        this.refreshGamePageList(this.game.id!);
      }, error => {
        this.snackBarService.openSnackBar(IndiepassCodesEnum.GAMEPAGE_SUPPRIME_KO, ActionsEnum.Ok, PannelsClassEnum.error)

      })
    }

  }

  refreshGamePageList(id: number) {
    this.gamePageService.getGamePages(id).subscribe(gamePages => {
      this.gamePages = gamePages;
    })
  }

  duplicateGamePage(gamePage: GamePage): void {
    // Implémenter la logique pour dupliquer la page de jeu
  }
}
