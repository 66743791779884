
<mat-card [style.background-image]="setBackGround()" >

  <mat-card-title>{{ title }}</mat-card-title>

  <mat-card-subtitle>
    <div *ngIf="lienAccepteVueDev" fxLayout="row">
      <div>Etat :</div>
      <div class="{{game?.state}}">{{ game?.state }}</div>
    </div>
    <div *ngIf="!lienAccepteVueDev" fxLayout="row">
      <div>Publisher :</div>
      <div class="">{{ game?.publisher?.name }}</div>
    </div>
  </mat-card-subtitle>
  <div class="attente-validation-lien" *ngIf="!lienAccepte">En attente de validation du développeur</div>
  <mat-card-actions>
    <div *ngIf="lienAccepteVueDev">
      <button *ngIf="game?.state?.toString() ===  'EXPLOITED'" (click)="demanderAnnulation()" mat-raised-button
              class="annuler" color="primary">Demande de retrait
      </button>
      <button *ngIf="game?.state?.toString() ===  'EXPLOITED'" (click)="demanderAnnulation()" mat-raised-button
              class="annuler" color="primary">Demande de retrait
      </button>
      <button (click)="goToGameManager()" mat-raised-button
              class="demanderValidation" color="primary">Game manage
      </button>
    </div>
    <div *ngIf="!lienAccepteVueDev">
      <button (click)="updateLinkState(true)" mat-raised-button
              class="demanderValidation" color="primary">Valider le lien
      </button>
      <button (click)="updateLinkState(false)" mat-raised-button
              class="annuler" color="primary">Refuser le lien
      </button>
    </div>
  </mat-card-actions>
</mat-card>

