import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {HostListener} from '@angular/core';

@Component({
  selector: 'app-multilangue',
  templateUrl: './multilangue.component.html',
  styleUrls: ['./multilangue.component.scss']
})
export class MultilangueComponent {
  dropdownOpen = false;
  availableLanguages = [
    {libelle: 'English', code: 'EN'},
    {libelle: 'Français', code: 'FR'},
    {libelle: 'Spanish', code: 'SP'},
    {libelle: 'Deutsch', code: 'DE'}];
  showed: boolean = false;

  @Output() clickoutEvent = new EventEmitter<null>();
  @Input() languageSelected: string = 'EN';
  @Output() selectedLanguageChange = new EventEmitter<string>();
  @Output() deleteLangue = new EventEmitter();

  constructor(private eRef: ElementRef) {
  }

  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if (!this.eRef.nativeElement.contains(event.target) && !this.dropdownOpen) {
      if (this.showed)
        this.clickoutEvent.emit();
      this.showed = true;
    }
  }


  onLanguageSelect(newLang: string): void {
    this.languageSelected = newLang;
    this.selectedLanguageChange.emit(newLang);
  }
  sendDeleteLangue(){
    this.deleteLangue.emit();
  }
  onDropdownOpenedChange(opened: boolean): void {
    this.dropdownOpen = opened;
  }
}
