import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {ConditionsPopupComponent} from "./conditions-popup/conditions-popup.component";
import {environment} from "../../../environments/environment";
import {GameStates} from "../../../model/enums/game-states";
import {Game} from "../../swagger/model/game";
import {ChoixPagePopupComponent} from "./choix-page-popup/choix-page-popup.component";
import {OrganizationService} from "../../swagger/api/organization.service";
import {IndiepassModalsService} from "../../services/indiepass-modals.service";
import {IndiePassModalMessage, IndiePassModalTitle} from "../../services/indiepass-modals/indiepass-modals.component";

@Component({
  selector: 'app-jeu-element',
  templateUrl: './jeu-element.component.html',
  styleUrls: ['./jeu-element.component.scss']
})
export class JeuElementComponent implements OnInit {
  public GameStates = GameStates;
  @Input()
  title: string = ""
  @Input()
  lienAccepte: boolean | undefined = true;
  @Input()
  lienAccepteVueDev: boolean | undefined = true;
  @Input()
  game: Game;

  constructor(private organizationService: OrganizationService, private router: Router, public dialog: MatDialog, private indiePassModalService: IndiepassModalsService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {

  }

  modifierPageMagasin() {
    //this.router.navigate(["/create/"+this.game?.id])
    const dialogRef = this.dialog.open(ChoixPagePopupComponent, {
      width: '750px',
      data: this.game,
    });
  }

  demanderValidation() {
    const dialogRef = this.indiePassModalService.confirmationCustom(IndiePassModalMessage.MD_MSG_CONFIRM_DELETE, IndiePassModalTitle.MD_TITLE_ASK_PUBLISH, true)

    dialogRef.subscribe(result => {
      if (result !== undefined) {
      }
    });
  }

  goToGameManager() {
    this.router.navigate(['./' + this.game?.id + '/manage/pages'], {relativeTo: this.route});
  }

  demanderAnnulation() {
    const dialogRef = this.dialog.open(ConditionsPopupComponent, {
      width: '550px',
      data: {
        titre: 'Demande d\'annulation',
        text: 'Vous êtes sur le point d\'annuler votre demande'
      },

    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {

      }
    });
  }

  demanderRetrait() {
    const dialogRef = this.dialog.open(ConditionsPopupComponent, {
      width: '550px',
      data: {
        titre: 'Demande de retrait',
        text: 'Vous êtes sur le point de demander le retrait de votre jeu'
      },

    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {

      }
    });
  }

  updateLinkState(value: boolean) {
    this.organizationService.acceptLink(this.game?.id!, value).subscribe();
  }

  getBanniere(id: number | undefined) {
    let dangerousVideoUrl = environment.urlimgcdn + id + '/gamePage/' + this?.game?.gamePages?.[0]?.translations?.[0]?.graphics?.cover?.fileName;
    return dangerousVideoUrl;
  }

  setBackGround() {
    let backGroundValue = 'linear-gradient( to right, rgb(1, 3, 14), rgb(0 0 255 / 0%) )';
    if (this?.game?.gamePages?.[0]?.translations?.[0]?.graphics?.cover?.fileName != null) {
      backGroundValue + ",url(" + environment.urlimgcdn + this?.game?.gamePages?.[0]?.translations?.[0]?.graphics?.cover?.fileName + ")";
    }
    return backGroundValue;
  }

}
