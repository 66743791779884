<div class="language-panel">
  <mat-form-field>
    <mat-label>Language</mat-label>
    <mat-select [value]="languageSelected" (valueChange)="onLanguageSelect($event)" (openedChange)="onDropdownOpenedChange($event)">
      <mat-option *ngFor="let lang of availableLanguages" [value]="lang.code">{{ lang.libelle }}</mat-option>
    </mat-select>

  </mat-form-field>
  <button *ngIf="languageSelected !== 'EN'" (click)="sendDeleteLangue()">
    <mat-icon>delete</mat-icon>
  </button>
</div>
