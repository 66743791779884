import {Envs} from "../model/enums/envs";

export const environment = {
  urlimgcdn : "https://imgcdn.indiepass.com/dev/",
  googleTagManagerId : "G-HF89RH8RYZ",
  googleTagManagerIdCookie : "_ga_HF89RH8RYZ",
  mainDomain : "https://dev.indiepass.com",
  urlClientDDL: "https://client.dev.indiepass.com/IndiePass Setup.exe",
  basePath: "https://api.dev.indiepass.com/v1",
  environment: Envs.DEV,
  presentation: false,
  production: false,
  baseUrlAPI :"https://api.dev.indiepass.com/v1",
  isBeta: false,
};
