import {Component, Inject, OnInit} from '@angular/core';
import {OrganizationService} from "../../../../swagger/api/organization.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {RouteParamService} from "../../../../services/route-param.service";
import {FormControl, Validators} from "@angular/forms";
import {HttpHeaders} from "@angular/common/http";
import {GameService} from "../../../../swagger/api/game.service";
import {GeneralService} from "../../../../general.service";
import {ImageService} from "../../../../services/image.service";
import {GameRelease} from "../../../../swagger/model/gameRelease";

@Component({
  selector: 'app-build-popup',
  templateUrl: './build-popup.component.html',
  styleUrls: ['./build-popup.component.scss']
})
export class BuildPopupComponent implements OnInit {


  constructor(private organizationService: OrganizationService, @Inject(MAT_DIALOG_DATA) public id: number,
              public dialogRef: MatDialogRef<BuildPopupComponent>, private routeParamService: RouteParamService,
              private gameService: GameService, private generalService: GeneralService, private imageService: ImageService) {
  }
  public uploadProgress = 0;
  major = new FormControl(0);
  minor = new FormControl(0);
  patch = new FormControl(0);

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  addmember() {
    this.organizationService.addMember(this.routeParamService.getRouteParameterNumber('idOrganization')!, this.major?.value).subscribe(url => {
      this.dialogRef.close();
    });
  }

  validateAndCorrect(control: FormControl) {
    let value = control.value;
    console.log(control)
    if (!value) {
      value = 0;
    }
    if (value !== null) {
      // Assurez-vous que la valeur ne commence pas par 0
      if (value.toString().charAt(0) === '0' && value > 0) {
        value = value.toString().slice(1);
      }
      value = Number.parseInt(value.toString().replace("+", ''));
      console.log(value)
      // Limitez la valeur à 9999 si elle dépasse
      if (value > 9999) {
        value = 9999;
      }
      if (value < 0) {
        value = 0;
      }
      // Mettre à jour la valeur du champ avec la valeur corrigée
      control.setValue(value);
    }
  }


  resetFileInput(fileInput: HTMLInputElement) {
    // Réinitialiser la valeur du champ de fichier
    fileInput.value = '';
    // Vous pouvez ajouter ici d'autres logiques si nécessaire
  }

  requestUpload(event: any) {
    if (event.target.files) {
      const files: File[] = event.target.files;
      if (files.length === 1) {
        let version: GameRelease = {}
        version.major = this.major.value
        version.minor = this.minor.value
        version.patch = this.patch.value
        const headers = new HttpHeaders().set('Content-Type', 'application/zip');
        this.gameService.uploadGameRelease(version, "paris", this.generalService.getUserInfos().gameId!).subscribe((uploadObject) => {
          this.imageService.uploadImage(files[0], uploadObject.linkUpload!, headers).subscribe(
            (progress) => {
              this.uploadProgress = progress;
              console.log(progress)
            },
            (error) => {

            },
            () => {

            }
          );
        });
      }

    }
  }

  protected readonly document = document;
}
