<div class="white-theme">
  <h1>New Build</h1>
  <div>
    Le build doit avoir une version suppérieur au dernier build disponible.
    L'indice major ne peut être trop éloigné du précédent.
    <form>
      <div class="version">
        <mat-form-field>
          <mat-label>Major</mat-label>
          <input matInput [formControl]="major" placeholder="0" (input)="validateAndCorrect(major)">
        </mat-form-field>
        <span>.</span>
        <mat-form-field>
          <mat-label>Minor</mat-label>
          <input matInput [formControl]="minor" placeholder="0" (input)="validateAndCorrect(minor)">
        </mat-form-field>
        <span>.</span>
        <mat-form-field>
          <mat-label>Patch</mat-label>
          <input matInput [formControl]="patch" placeholder="0" (input)="validateAndCorrect(patch)">
        </mat-form-field>
      </div>
    </form>

    <div mat-dialog-actions>
      <button mat-button mat-raised-button (click)="onNoClick()">Annuler</button>

      <button mat-button color="primary" (click)="uploadBuildInput.click()" type="button"
              mat-flat-button>
        Upload a build
        <input style="display:none;" #uploadBuildInput (change)="requestUpload($event)"
               (click)="resetFileInput(uploadBuildInput)" type="file"
               name="image" accept="application/zip"/>
      </button>
    </div>
  </div>
</div>
